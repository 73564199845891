import { createFeature, createReducer, on } from '@ngrx/store';
import { CampaignOnlineStatsActions } from './campaign-online-stats.actions';
import { DialerCampaign_OnlineStats } from 'src/app/shared/models';

export const campaignOnlineStatsFeatureKey = 'campaignOnlineStats';

export interface CampaignOnlineStatsState {
  campaignOnlineStat: DialerCampaign_OnlineStats | null;
  campaignOnlineStats: DialerCampaign_OnlineStats[];
  loading: boolean;
  error: any;
}

export const initialState: CampaignOnlineStatsState = {
  campaignOnlineStat: null,
  campaignOnlineStats: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CampaignOnlineStatsActions.loadCampaignOnlineStats,
    (state): CampaignOnlineStatsState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    CampaignOnlineStatsActions.loadCampaignOnlineStatsSuccess,
    (state, { campaignOnlineStat }): CampaignOnlineStatsState => ({
      ...state,
      campaignOnlineStats: [campaignOnlineStat],
      campaignOnlineStat,
      loading: false,
      error: null,
    }),
  ),
  on(
    CampaignOnlineStatsActions.loadCampaignOnlineStatsFailure,
    (state, { error }): CampaignOnlineStatsState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const campaignOnlineStatsFeature = createFeature({
  name: campaignOnlineStatsFeatureKey,
  reducer,
});

<router-outlet></router-outlet>

<audio #remoteAudio [autoplay]="true"></audio>
<audio #localAudio [autoplay]="true"></audio>
<div id="superviseAudioContainer"></div>

@if (version) {
  <div class="fixed text-slate-500 right-6 bottom-0 z-10 font-4p">
    {{ version }}
  </div>
}

@if (authService.locked$ | async) {
  <div
    class="fixed left-0 right-0 top-0 bottom-0 bg-primary bg-opacity-40 z-[100]"
  >
    <div
      class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
    >
      <fa-icon [icon]="faLock" size="4x" class="mb-2"></fa-icon>
      <h3>Locked</h3>
      <p class="mb-4">You are already logged in in another tab or window.</p>

      <p class="mb-2">Do you want to transfer your session here?</p>
      <button class="btn btn-neutral" (click)="moveHere()">
        <fa-icon [icon]="faPaperPlane" class="mr-2"></fa-icon> Transfer
      </button>
    </div>
  </div>
}

@if (authService.noAccess$ | async) {
  <div
    class="fixed left-0 right-0 top-0 bottom-0 bg-red-400 bg-opacity-40 z-[100]"
  >
    <div class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <fa-icon [icon]="faLock" size="4x" class="mb-2"></fa-icon>
      <h3>You don't have access to an agency</h3>
      <p class="mb-4">
        Please contact your administrator to invite your account.
      </p>

      <button class="btn btn-neutral" (click)="backToEH()">
        <fa-icon [icon]="faTurnLeft" class="mr-2"></fa-icon> Back to
        enrollhere.com
      </button>
    </div>
  </div>
}

@if (isOffline()) {
  <div
    class="fixed left-0 right-0 top-0 bottom-0 bg-red-400 bg-opacity-50 z-[100]"
  >
    <div class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <fa-icon [icon]="faWifiExclamation" size="4x"></fa-icon>
      <h3>Your Device is offline</h3>
      <p>Go back online to continue</p>
    </div>
  </div>
}

@if (audioService.audioNotGranted$ | async) {
  <div
    class="fixed left-0 right-0 top-0 bottom-0 bg-red-400 bg-opacity-50 z-[100]"
  >
    <div class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <fa-icon [icon]="faHeadset" size="4x"></fa-icon>
      <h3>No Access to Microphone</h3>
      <p>Please grant Access in your Browser Settings</p>
    </div>
  </div>
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PermissionActions } from './permission.actions';
import {
  Firestore,
  collection,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { User_Permission } from '../../models';
import { getUserCollection } from '../../misc/getUserCollection';
import { PermissionService } from '../../services/user/permission.service';

@Injectable()
export class PermissionEffects {
  loadPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionActions.loadPermissions),
      switchMap(({ userId, userType }) => {
        return new Observable<Action>(subscriber => {
          const collectionName = getUserCollection(userType);

          if (!collectionName) {
            throw new Error('Invalid user type');
          }
          // const unsubscribe = onSnapshot(
          //   doc(
          //     this.firestore,
          //     collectionName,
          //     userId,
          //     'private',
          //     'permissions'
          //   ),
          //   snapshot => {
          //     const permissions = User_PermissionContainer.fromJSON(
          //       snapshot.data()
          //     );

          //     const permissionList: User_Permission[] = [];
          //     for (const i in permissions.permissions) {
          //       permissionList.push({
          //         ...permissions.permissions[i],
          //         userId: i,
          //       });
          //     }

          //     subscriber.next(
          //       PermissionActions.loadPermissionsSuccess({
          //         permissions: permissionList,
          //       })
          //     );
          //   },
          //   error => {
          //     subscriber.next(
          //       PermissionActions.loadPermissionsFailure({ error })
          //     );
          //   }
          // );
          const unsubscribe = onSnapshot(
            collection(
              this.firestore,
              collectionName,
              userId,
              'private',
              'permissions',
              'sent'
            ),
            snapshot => {
              if (snapshot.empty) {
                subscriber.next(
                  PermissionActions.loadPermissionsSuccess({
                    permissions: [],
                  })
                );
                return;
              }

              const permissionList = snapshot.docs.map(doc => {
                return User_Permission.fromJSON({
                  ...doc.data(),
                  userId: doc.id,
                });
              });

              subscriber.next(
                PermissionActions.loadPermissionsSuccess({
                  permissions: permissionList,
                })
              );
            },
            error => {
              subscriber.next(
                PermissionActions.loadPermissionsFailure({ error })
              );
            }
          );
          // Provide a way of and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Permission API] Load Permissions Error', error })
          )
        );
      })
    );
  });

  loadPermission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionActions.loadPermission),
      switchMap(({ grantedId, granterId, userType }) => {
        return new Observable<Action>(subscriber => {
          const collectionName = getUserCollection(userType);
console.log('loadPermission', { grantedId, granterId, userType, collectionName });
          if (!collectionName) {
            throw new Error('Invalid user type');
          }

          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              collectionName,
              granterId,
              'private',
              'permissions',
              'sent',
              grantedId
            ),
            snapshot => {
              const permission = User_Permission.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              console.log('loadPermission done', { permission });
              subscriber.next(
                PermissionActions.loadPermissionSuccess({ permission })
              );
            },
            error => {
              subscriber.next(
                PermissionActions.loadPermissionFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  // addPermission$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionActions.addPermission),
  //     mergeMap(async ({ consumerId, permission }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'consumers', consumerId, 'permissions'),
  //           permission
  //         );
  //         return PermissionActions.addPermissionSuccess({
  //           permission: Permission.fromJSON({ ...permission, id: docRef.id }),
  //         }); // return new permission with id
  //       } catch (error) {
  //         return PermissionActions.addPermissionFailure({ error });
  //       }
  //     })
  //   );
  // });

  // removePermission$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionActions.removePermission),
  //     mergeMap(async ({ consumerId, permissionId }) => {
  //       try {
  //         await deleteDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissions',
  //             permissionId
  //           )
  //         );
  //         return PermissionActions.removePermissionSuccess({ permissionId }); // return removed permission's id
  //       } catch (error) {
  //         return PermissionActions.removePermissionFailure({ error });
  //       }
  //     })
  //   );
  // });

  // updatePermission$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionActions.updatePermission),
  //     mergeMap(
  //       async ({
  //         grantedId,
  //         granterId,
  //         permission,
  //         userCollection,
  //         password,
  //       }) => {
  //         if (permission.user && permission.user?.userRole) {
  //           console.log('setPermission', {
  //             grantedId,
  //             granterId,
  //             permission,
  //             userCollection,
  //             password: password ? 'set' : 'unset',
  //           });
  //           const result = await this.permissionService.setPermission(
  //             {
  //               ...permission.user,
  //               id: grantedId,
  //             },
  //             permission.user.userRole,
  //             permission.user.dialerRole,
  //             true,
  //             undefined,
  //             undefined,
  //             undefined,
  //             undefined,
  //             userCollection,
  //             {
  //               password,
  //             }
  //           );

  //           console.info('updatePermission', { result });

  //           if (result) {
  //             return PermissionActions.updatePermissionSuccess({
  //               grantedId,
  //               granterId,
  //               permission,
  //             });
  //           }
  //         }
  //         return PermissionActions.updatePermissionFailure({
  //           error: new Error('No user'),
  //         });
  //       }
  //     )
  //   );
  // });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
    private permissionService: PermissionService
  ) { }
}

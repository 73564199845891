import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DialerCampaign_OnlineStats } from 'src/app/shared/models';

export const CampaignOnlineStatsActions = createActionGroup({
  source: 'CampaignOnlineStats',
  events: {
    'Load CampaignOnlineStats': props<{
      campaignId: string;
      agencyId: string;
    }>(),
    'Load CampaignOnlineStats Success': props<{
      campaignOnlineStat: DialerCampaign_OnlineStats;
    }>(),
    'Load CampaignOnlineStats Failure': props<{ error: any }>(),
  },
});

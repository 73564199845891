import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { QueueLiveStatsActions } from './queue-live-stats.actions';
import {
  Firestore,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerLiveStats } from 'src/app/shared/models';

@Injectable()
export class QueueLiveStatsEffects {
  loadQueueLiveStats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueLiveStatsActions.loadQueueLiveStats),
      mergeMap(({ agencyId, queueId }) => {
        return new Observable<Action>((subscriber) => {
          onSnapshot(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'queues',
              queueId,
              'stats',
              'today',
            ),
            (snapshot) => {
              const data = snapshot.data();

              const liveCount = Object.keys(data?.['live'] ?? {})?.length;
              const queueLiveStat = DialerLiveStats.fromJSON({
                id: queueId,
                parentId: agencyId,
                liveCount,
                ...data,
              });
              subscriber.next(
                QueueLiveStatsActions.loadQueueLiveStatsSuccess({
                  queueLiveStat,
                }),
              );
            },
            (error) => {
              subscriber.next(
                QueueLiveStatsActions.loadQueueLiveStatsFailure({
                  error,
                }),
              );
            },
          );

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({
              type: '[QueueLiveStats API] Load QueueLiveStats Error',
              error,
            }),
          ),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DialerAgentActions } from './dialer-agent.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { DialerAgent } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class DialerAgentEffects {

  loadDialerAgentsSubscriptions: { [key: string]: Observable<Action> } = {}

  loadDialerAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DialerAgentActions.loadDialerAgents),
      mergeMap(({ agencyId }) => {
        if (!this.loadDialerAgentsSubscriptions[agencyId]) {
          this.loadDialerAgentsSubscriptions[agencyId] = new Observable<Action>((subscriber) => {
            let q = query(collection(this.firestore, 'agencies', agencyId, 'agents'));

            const unsubscribe = onSnapshot(
              q,
              (snapshot) => {
                const agents = snapshot.docs.map((doc) =>
                  DialerAgent.fromJSON({ ...doc.data(), id: doc.id, agencyId, agency: { id: agencyId } }),
                );
                subscriber.next(DialerAgentActions.loadDialerAgentsSuccess({ agents: agents }));
              },
              (error) => {
                subscriber.next(DialerAgentActions.loadDialerAgentsFailure({ error }));
              },
            );

            // Provide a way of canceling and disposing the listener
            return unsubscribe;
          }).pipe(
            catchError((error) =>
              of({ type: '[DialerAgent API] Load DialerAgents Error', error }),
            ),
          );
        }
        return this.loadDialerAgentsSubscriptions[agencyId]
      }),
    );
  });

  loadDialerAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DialerAgentActions.loadDialerAgent),
      switchMap(({ agencyId, agentId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'agents', agentId),
            (snapshot) => {
              const agent = DialerAgent.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId,
                agency: { id: agencyId }
              });
              subscriber.next(DialerAgentActions.loadDialerAgentSuccess({ agent }));
            },
            (error) => {
              subscriber.next(DialerAgentActions.loadDialerAgentFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addDialerAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DialerAgentActions.addDialerAgent),
      mergeMap(async ({ agent, agencyId }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'agents'),
            agent,
          );
          return DialerAgentActions.addDialerAgentSuccess({
            agent: DialerAgent.fromJSON({ ...agent, id: docRef.id, agencyId, agency: { id: agencyId } }),
          }); // return new dialerAgent with id
        } catch (error) {
          return DialerAgentActions.addDialerAgentFailure({ error });
        }
      }),
    );
  });

  removeDialerAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DialerAgentActions.removeDialerAgent),
      mergeMap(async ({ agencyId, agentId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agencies', agencyId, 'agents', agentId));
          return DialerAgentActions.removeDialerAgentSuccess({ agencyId, agentId }); // return removed dialerAgent's id
        } catch (error) {
          return DialerAgentActions.removeDialerAgentFailure({ error });
        }
      }),
    );
  });

  updateDialerAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DialerAgentActions.updateDialerAgent),
      mergeMap(async ({ agencyId, agentId, agent }) => {
        console.log('updateDialerAgentAction', agentId, agent, DialerAgent.toJSON(agent));
        try {
          await setDoc(doc(this.firestore, 'agencies', agencyId, 'agents', agentId), DialerAgent.toJSON(agent) as DocumentData, {
            merge: true,
          });
          return DialerAgentActions.updateDialerAgentSuccess({
            agencyId,
            agentId,
            agent,
          }); // return updated dialerAgent's id and changes
        } catch (error) {
          console.error(error);
          return DialerAgentActions.updateDialerAgentFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DispoStatusActions } from './dispo-status.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerDispoStatus } from 'src/app/shared/models';
import { convertDotNotationToObject } from 'src/app/shared/misc/convertObjectToDotNotation';

@Injectable()
export class DispoStatusEffects {
  loadDispoStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DispoStatusActions.loadDispoStatuses),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'dispoStatus'),
            (snapshot) => {
              const dispoStatuses = snapshot.docs.map((doc) => {
                const data = doc.data()

                return DialerDispoStatus.fromJSON({ ...data, id: doc.id, agency: { id: agencyId } })
              });
              subscriber.next(
                DispoStatusActions.loadDispoStatusesSuccess({ dispoStatuses }),
              );
            },
            (error) => {
              subscriber.next(DispoStatusActions.loadDispoStatusesFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[DispoStatus API] Load DispoStatuses Error', error }),
          ),
        );
      }),
    );
  });

  loadDispoStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DispoStatusActions.loadDispoStatus),
      switchMap(({ dispoStatusId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'dispoStatus', dispoStatusId),
            (snapshot) => {
              const data = snapshot.data();

              const dispoStatus = DialerDispoStatus.fromJSON({
                ...data,
                id: snapshot.id,
                agency: { id: agencyId },
              });

              subscriber.next(
                DispoStatusActions.loadDispoStatusSuccess({ dispoStatus }),
              );
            },
            (error) => {
              subscriber.next(DispoStatusActions.loadDispoStatusFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addDispoStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DispoStatusActions.addDispoStatus),
      mergeMap(async ({ dispoStatus, agencyId }) => {
        console.log('addDispoStatusAction', dispoStatus);

        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'dispoStatus'),
            DialerDispoStatus.toJSON(dispoStatus),
          );
          return DispoStatusActions.addDispoStatusSuccess({
            dispoStatus: DialerDispoStatus.fromJSON({ ...dispoStatus, id: docRef.id, agency: { id: agencyId } }),
          }); // return new dispoStatus with id
        } catch (error) {
          return DispoStatusActions.addDispoStatusFailure({ error });
        }
      }),
    );
  });

  removeDispoStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DispoStatusActions.removeDispoStatus),
      mergeMap(async ({ dispoStatusId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'dispoStatus', dispoStatusId),
          );
          return DispoStatusActions.removeDispoStatusSuccess({ dispoStatusId }); // return removed dispoStatus's id
        } catch (error) {
          return DispoStatusActions.removeDispoStatusFailure({ error });
        }
      }),
    );
  });

  updateDispoStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DispoStatusActions.updateDispoStatus),
      mergeMap(async ({ dispoStatusId, dispoStatus, agencyId, merge }) => {
        console.log('updateDispoStatusAction', dispoStatusId, dispoStatus);

        if (merge === undefined || merge === null) {
          merge = true
        }

        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'dispoStatus', dispoStatusId),
            DialerDispoStatus.toJSON(dispoStatus) as unknown as DocumentData,
            {
              merge: merge,
            },
          );
          return DispoStatusActions.updateDispoStatusSuccess({
            dispoStatusId,
            agencyId,
            dispoStatus: { ...dispoStatus, agency: { id: agencyId } },
          }); // return updated dispoStatus's id and changes
        } catch (error) {
          return DispoStatusActions.updateDispoStatusFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

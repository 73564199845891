import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgentActions } from './agent.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Agent } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class AgentEffects {
  loadAgents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgents),
      switchMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          let q = query(collection(this.firestore, 'agents'));

          if (agencyId) {
            q = query(q, where(`agencies.${agencyId}.active`, '==', true));
          }

          const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
              const agents = snapshot.docs.map((doc) =>
                Agent.fromJSON({ ...doc.data(), id: doc.id }),
              );
              subscriber.next(AgentActions.loadAgentsSuccess({ agents }));
            },
            (error) => {
              subscriber.next(AgentActions.loadAgentsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Agent API] Load Agents Error', error }),
          ),
        );
      }),
    );
  });

  loadAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.loadAgent),
      switchMap(({ agentId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agents', agentId),
            (snapshot) => {
              const agent = Agent.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(AgentActions.loadAgentSuccess({ agent }));
            },
            (error) => {
              subscriber.next(AgentActions.loadAgentFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.addAgent),
      mergeMap(async ({ agent }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agents'),
            agent,
          );
          return AgentActions.addAgentSuccess({
            agent: Agent.fromJSON({ ...agent, id: docRef.id }),
          }); // return new agent with id
        } catch (error) {
          return AgentActions.addAgentFailure({ error });
        }
      }),
    );
  });

  removeAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.removeAgent),
      mergeMap(async ({ agentId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agents', agentId));
          return AgentActions.removeAgentSuccess({ agentId }); // return removed agent's id
        } catch (error) {
          return AgentActions.removeAgentFailure({ error });
        }
      }),
    );
  });

  updateAgent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgentActions.updateAgent),
      mergeMap(async ({ agentId, agent }) => {
        console.log('updateAgentAction', agentId, agent);
        try {
          await setDoc(doc(this.firestore, 'agents', agentId), agent, {
            merge: true,
          });
          return AgentActions.updateAgentSuccess({
            agentId,
            agent,
          }); // return updated agent's id and changes
        } catch (error) {
          console.error(error);
          return AgentActions.updateAgentFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) {}
}

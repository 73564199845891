import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LeadActions } from './lead.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Consumer } from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import { ElasticService } from 'src/app/shared/services/elastic.service';

@Injectable()
export class LeadEffects {
  loadLeads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.loadLeads),
      switchMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'leads'),
            (snapshot) => {
              const leads = snapshot.docs.map((doc) =>
                Consumer.fromJSON({ ...doc.data(), id: doc.id }),
              );
              subscriber.next(LeadActions.loadLeadsSuccess({ leads }));
            },
            (error) => {
              subscriber.next(LeadActions.loadLeadsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Lead API] Load Leads Error', error }),
          ),
        );
      }),
    );
  });

  loadLeadsByCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.loadLeadsByCampaign),
      switchMap(({ agencyId, campaignId }) => {
        return new Observable<Action>((subscriber) => {
          this.elasticService
            .query('leads', {
              size: 1000,
              query: {
                bool: {
                  must: [
                    {
                      match: {
                        'campaign.id': campaignId,
                      },
                    },
                    {
                      match: {
                        'agency.id': agencyId,
                      },
                    },
                    // {
                    //   bool: {
                    //     should: [
                    //       { match: { 'dialer.statusCategory': 'RECYCLE' } },
                    //       {
                    //         bool: {
                    //           must_not: [{ exists: { field: 'dialer.statusCategory' } }],
                    //           // must: [{ exists: { field: 'firstName' } }],
                    //         },
                    //       },
                    //     ],
                    //   },
                    // },
                  ],
                },
              },
            })
            .then((res) => {
              const leads = res.hits.hits.map((hit: any) =>
                Consumer.fromJSON({ ...hit._source, id: hit._id }),
              );
              subscriber.next(LeadActions.loadLeadsSuccess({ leads }));
            })
            .catch((error) => {
              subscriber.next(LeadActions.loadLeadsFailure({ error }));
            });
        }).pipe(
          catchError((error) =>
            of({ type: '[Lead API] Load Leads Error', error }),
          ),
        );
      }),
    );
  });
  loadLeadsByCampaignLive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.loadLeadsLiveByCampaign),
      switchMap(({ agencyId, campaignId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            query(
              collection(this.firestore, 'agencies', agencyId, 'leads'),
              where('campaign.id', '==', campaignId),
            ),
            (snapshot) => {
              console.log('leads live', agencyId, campaignId, snapshot.docs)
              const leads = snapshot.docs.map((doc) =>
                Consumer.fromJSON({ ...doc.data(), id: doc.id }),
              );
              subscriber.next(LeadActions.loadLeadsLiveSuccess({ leads }));
            },
            (error) => {
              subscriber.next(LeadActions.loadLeadsLiveFailure({ error }));
            },
          );
        }).pipe(
          catchError((error) =>
            of({ type: '[Lead API] Load Leads Live Error', error }),
          ),
        );
      }),
    );
  });

  loadLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.loadLead),
      switchMap(({ leadId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId),
            (snapshot) => {
              const lead = Consumer.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              lead.agency = { id: agencyId };
              subscriber.next(LeadActions.loadLeadSuccess({ lead }));
            },
            (error) => {
              subscriber.next(LeadActions.loadLeadFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.addLead),
      mergeMap(async ({ lead, agencyId }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'leads'),
            lead,
          );
          return LeadActions.addLeadSuccess({
            lead: Consumer.fromJSON({ ...lead, id: docRef.id }),
          }); // return new lead with id
        } catch (error) {
          return LeadActions.addLeadFailure({ error });
        }
      }),
    );
  });

  removeLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.removeLead),
      mergeMap(async ({ leadId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId),
          );
          return LeadActions.removeLeadSuccess({
            leadId,
            agencyId,
          }); // return removed lead's id
        } catch (error) {
          return LeadActions.removeLeadFailure({ error });
        }
      }),
    );
  });

  updateLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadActions.updateLead),
      mergeMap(async ({ leadId, agencyId, lead }) => {
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId),
            lead,
            {
              merge: true,
            },
          );
          return LeadActions.updateLeadSuccess({
            leadId,
            agencyId,
            lead,
          }); // return updated lead's id and changes
        } catch (error) {
          return LeadActions.updateLeadFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private elasticService: ElasticService,
    private firestore: Firestore,
  ) {}
}

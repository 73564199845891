import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LeadListActions } from './lead-list.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { LeadList } from 'src/app/shared/models';

@Injectable()
export class LeadListEffects {
  loadLeadLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.loadLeadLists),
      mergeMap(({ agencyId }) => {
        console.log('loadLeadListsAction', agencyId)
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'leadLists'),
            (snapshot) => {
              const leadLists = snapshot.docs.map((doc) =>
                LeadList.fromJSON({ ...doc.data(), id: doc.id, agencyId }),
              );
              subscriber.next(
                LeadListActions.loadLeadListsSuccess({ leadLists }),
              );
            },
            (error) => {
              subscriber.next(LeadListActions.loadLeadListsFailure({ error }));
            },
          );
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[LeadList API] Load LeadLists Error', error }),
          ),
        );
      }),
    );
  });

  loadLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.loadLeadList),
      switchMap(({ leadListId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leadLists', leadListId),
            (snapshot) => {
              const leadList = LeadList.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId
              });
              subscriber.next(
                LeadListActions.loadLeadListSuccess({ leadList }),
              );
            },
            (error) => {
              subscriber.next(LeadListActions.loadLeadListFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.addLeadList),
      mergeMap(async ({ leadList, agencyId }) => {
        console.log('addLeadListAction', leadList);
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'leadLists'),
            leadList,
          );
          return LeadListActions.addLeadListSuccess({
            leadList: LeadList.fromJSON({ ...leadList, id: docRef.id, agencyId }),
          }); // return new leadList with id
        } catch (error) {
          return LeadListActions.addLeadListFailure({ error });
        }
      }),
    );
  });

  removeLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.removeLeadList),
      mergeMap(async ({ leadListId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'leadLists', leadListId),
          );
          return LeadListActions.removeLeadListSuccess({ leadListId }); // return removed leadList's id
        } catch (error) {
          return LeadListActions.removeLeadListFailure({ error });
        }
      }),
    );
  });

  updateLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.updateLeadList),
      mergeMap(async ({ leadListId, leadList, agencyId }) => {
        console.log('updateLeadListAction', leadListId, leadList);
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'leadLists', leadListId),
            leadList,
            {
              merge: true,
            },
          );
          return LeadListActions.updateLeadListSuccess({
            leadListId,
            agencyId,
            leadList: { ...leadList, agencyId },
          }); // return updated leadList's id and changes
        } catch (error) {
          return LeadListActions.updateLeadListFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

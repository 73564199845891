import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ConsumerActions } from './consumer.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Consumer } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class ConsumerEffects {
  loadConsumers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerActions.loadConsumers),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'consumers'),
            (snapshot) => {
              const consumers = snapshot.docs.map((doc) =>
                Consumer.fromJSON({ ...doc.data(), id: doc.id }),
              );
              subscriber.next(
                ConsumerActions.loadConsumersSuccess({ consumers }),
              );
            },
            (error) => {
              subscriber.next(ConsumerActions.loadConsumersFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Consumer API] Load Consumers Error', error }),
          ),
        );
      }),
    );
  });

  loadConsumer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerActions.loadConsumer),
      switchMap(({ consumerId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'consumers', consumerId),
            (snapshot) => {
              const consumer = Consumer.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                ConsumerActions.loadConsumerSuccess({ consumer }),
              );
            },
            (error) => {
              subscriber.next(ConsumerActions.loadConsumerFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addConsumer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerActions.addConsumer),
      mergeMap(async ({ consumer, agencyId }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'consumers'),
            consumer,
          );
          return ConsumerActions.addConsumerSuccess({
            consumer: Consumer.fromJSON({ ...consumer, id: docRef.id }),
          }); // return new consumer with id
        } catch (error) {
          return ConsumerActions.addConsumerFailure({ error });
        }
      }),
    );
  });

  removeConsumer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerActions.removeConsumer),
      mergeMap(async ({ consumerId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'consumers', consumerId),
          );
          return ConsumerActions.removeConsumerSuccess({
            consumerId,
            agencyId,
          }); // return removed consumer's id
        } catch (error) {
          return ConsumerActions.removeConsumerFailure({ error });
        }
      }),
    );
  });

  updateConsumer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConsumerActions.updateConsumer),
      mergeMap(async ({ consumerId, agencyId, consumer }) => {
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'consumers', consumerId),
            consumer,
            {
              merge: true,
            },
          );
          return ConsumerActions.updateConsumerSuccess({
            consumerId,
            agencyId,
            consumer,
          }); // return updated consumer's id and changes
        } catch (error) {
          return ConsumerActions.updateConsumerFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

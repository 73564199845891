import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models';

export const ConsumerActions = createActionGroup({
  source: 'Consumer',
  events: {
    'Load Consumers': props<{ agencyId: string }>(),
    'Load Consumers Success': props<{ consumers: Consumer[] }>(),
    'Load Consumers Failure': props<{ error: any }>(),
    'Load Consumer': props<{ consumerId: string; agencyId: string }>(),
    'Load Consumer Success': props<{ consumer: Consumer }>(),
    'Load Consumer Failure': props<{ error: any }>(),
    'Add Consumer': props<{ consumer: Consumer; agencyId: string }>(),
    'Add Consumer Success': props<{ consumer: Consumer }>(),
    'Add Consumer Failure': props<{ error: any }>(),
    'Remove Consumer': props<{ consumerId: string; agencyId: string }>(),
    'Remove Consumer Success': props<{
      consumerId: string;
      agencyId: string;
    }>(),
    'Remove Consumer Failure': props<{ error: any }>(),
    'Update Consumer': props<{
      consumerId: string;
      agencyId: string;
      consumer: Partial<Consumer>;
    }>(),
    'Update Consumer Success': props<{
      consumerId: string;
      agencyId: string;
      consumer: Partial<Consumer>;
    }>(),
    'Update Consumer Failure': props<{ error: any }>(),
  },
});

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CampaignLiveStatsActions } from './campaign-live-stats.actions';
import {
  Firestore,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerLiveStats } from 'src/app/shared/models';

@Injectable()
export class CampaignLiveStatsEffects {
  loadCampaignLiveStats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignLiveStatsActions.loadCampaignLiveStats),
      mergeMap(({ agencyId, campaignId }) => {
        return new Observable<Action>((subscriber) => {
          onSnapshot(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'campaigns',
              campaignId,
              'stats',
              'today',
            ),
            (snapshot) => {
              const data = snapshot.data();

              console.log('INFO', agencyId, campaignId, data)

              const campaignLiveStat = DialerLiveStats.fromJSON({
                id: campaignId,
                parentId: agencyId,
                ...data,
              });
              subscriber.next(
                CampaignLiveStatsActions.loadCampaignLiveStatsSuccess({
                  campaignLiveStat,
                }),
              );
            },
            (error) => {
              subscriber.next(
                CampaignLiveStatsActions.loadCampaignLiveStatsFailure({
                  error,
                }),
              );
            },
          );

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({
              type: '[CampaignLiveStats API] Load CampaignLiveStats Error',
              error,
            }),
          ),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

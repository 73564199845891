import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  PLATFORM_ID,
  importProvidersFrom,
  inject,
  isDevMode,
} from '@angular/core';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { environment } from 'src/environments/environment';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideServiceWorker } from '@angular/service-worker';
import { createErrorHandler, TraceService } from '@sentry/angular'
import { provideToastr } from 'ngx-toastr';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { isPlatformServer } from '@angular/common';
import { AgentEffects } from './shared/store/agent/+state/agent.effects';
import * as fromAgent from './shared/store/agent/+state/agent.reducer';
import { AgencyEffects } from './shared/store/agency/+state/agency.effects';
import * as fromAgency from './shared/store/agency/+state/agency.reducer';
import { CampaignEffects } from './shared/store/campaign/+state/campaign.effects';
import * as fromCampaign from './shared/store/campaign/+state/campaign.reducer';
import { EmployeeEffects } from './shared/store/employee/+state/employee.effects';
import * as fromEmployee from './shared/store/employee/+state/employee.reducer';
import { CampaignHopperEffects } from './shared/store/campaign-hopper/+state/campaign-hopper.effects';
import * as fromCampaignHopper from './shared/store/campaign-hopper/+state/campaign-hopper.reducer';
import { CampaignLiveStatsEffects } from './shared/store/campaign-live-stats/+state/campaign-live-stats.effects';
import * as fromCampaignLiveStats from './shared/store/campaign-live-stats/+state/campaign-live-stats.reducer';
import { CampaignOnlineStatsEffects } from './shared/store/campaign-online-stats/+state/campaign-online-stats.effects';
import * as fromCampaignOnlineStats from './shared/store/campaign-online-stats/+state/campaign-online-stats.reducer';
import { DialerAgentEffects } from './shared/store/dialer-agent/+state/dialer-agent.effects';
import * as fromDialerAgent from './shared/store/dialer-agent/+state/dialer-agent.reducer';
import { DispoStatusEffects } from './shared/store/dispo-status/dispo-status.effects';
import * as fromDispoStatus from './shared/store/dispo-status/dispo-status.reducer';
import { ConsumerEffects } from './shared/store/consumer/+state/consumer.effects';
import * as fromConsumer from './shared/store/consumer/+state/consumer.reducer';
import { LeadEffects } from './shared/store/lead/+state/lead.effects';
import * as fromLead from './shared/store/lead/+state/lead.reducer';
import { LeadCallEffects } from './shared/store/lead-call/+state/lead-call.effects';
import * as fromLeadCall from './shared/store/lead-call/+state/lead-call.reducer';
import { LeadListEffects } from './shared/store/lead-list/+state/lead-list.effects';
import * as fromLeadList from './shared/store/lead-list/+state/lead-list.reducer';
import { NumberEffects } from './shared/store/number/+state/number.effects';
import * as fromNumber from './shared/store/number/+state/number.reducer';
import { PermissionEffects } from './shared/store/permission/permission.effects';
import * as fromPermission from './shared/store/permission/permission.reducer';
import { PermissionGrantedEffects } from './shared/store/permission-granted/+state/permission-granted.effects';
import * as fromPermissionGranted from './shared/store/permission-granted/+state/permission-granted.reducer';
import { QuestionnaireEffects } from './shared/store/questionnaire/+state/questionnaire.effects';
import * as fromQuestionnaire from './shared/store/questionnaire/+state/questionnaire.reducer';
import { QueueEffects } from './shared/store/queue/+state/queue.effects';
import * as fromQueue from './shared/store/queue/+state/queue.reducer';
import { QueueLiveStatsEffects } from './shared/store/queue-live-stats/+state/queue-live-stats.effects';
import * as fromQueueLiveStats from './shared/store/queue-live-stats/+state/queue-live-stats.reducer';
import { ScriptEffects } from './shared/store/script/+state/script.effects';
import * as fromScript from './shared/store/script/+state/script.reducer';
import { StaticEffects } from './shared/store/static/+state/static.effects';
import * as fromStatic from './shared/store/static/+state/static.reducer';
import { SkillEffects } from './shared/store/skill/skill.effects';
import * as fromSkill from './shared/store/skill/skill.reducer';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

const provideSentryErrorHandler = (opts = {}) => {
  return {
    provide: ErrorHandler,
    useValue: createErrorHandler(opts),
  };
};

const provideSentryTraceService = () => {
  return {
    provide: TraceService,
    deps: [Router],
  };
};

const provideSentryInitializer = () => {
  return {
    provide: APP_INITIALIZER,
    useFactory: () => () => { },
    deps: [TraceService],
    multi: true,
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      })
    ),

    provideAnimations(),
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
    ),
    provideFunctions(() => getFunctions(undefined, 'us-east1')),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    //@ts-expect-error - Initialize only on client
    provideAppCheck(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformServer(platformId)) {
        return;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.firebase.appCheckRecaptchaSiteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    provideStore({
      [fromAgent.agentFeatureKey]: fromAgent.reducer,
      [fromAgency.agencyFeatureKey]: fromAgency.reducer,
      [fromCampaign.campaignFeatureKey]: fromCampaign.reducer,
      [fromCampaignHopper.campaignHopperFeatureKey]: fromCampaignHopper.reducer,
      [fromCampaignLiveStats.campaignLiveStatsFeatureKey]: fromCampaignLiveStats.reducer,
      [fromCampaignOnlineStats.campaignOnlineStatsFeatureKey]: fromCampaignOnlineStats.reducer,
      [fromConsumer.consumerFeatureKey]: fromConsumer.reducer,
      [fromDialerAgent.dialerAgentFeatureKey]: fromDialerAgent.reducer,
      [fromDispoStatus.dispoStatusFeatureKey]: fromDispoStatus.reducer,
      [fromEmployee.employeeFeatureKey]: fromEmployee.reducer,
      [fromLead.leadFeatureKey]: fromLead.reducer,
      [fromLeadCall.leadCallFeatureKey]: fromLeadCall.reducer,
      [fromLeadList.leadListFeatureKey]: fromLeadList.reducer,
      [fromNumber.numberFeatureKey]: fromNumber.reducer,
      [fromPermission.permissionFeatureKey]: fromPermission.reducer,
      [fromPermissionGranted.permissionGrantedFeatureKey]: fromPermissionGranted.reducer,
      [fromQuestionnaire.questionnaireFeatureKey]: fromQuestionnaire.reducer,
      [fromQueue.queueFeatureKey]: fromQueue.reducer,
      [fromQueueLiveStats.queueLiveStatsFeatureKey]: fromQueueLiveStats.reducer,
      [fromScript.scriptFeatureKey]: fromScript.reducer,
      [fromStatic.staticFeatureKey]: fromStatic.reducer,
      [fromSkill.skillFeatureKey]: fromSkill.reducer,
    }),
    provideEffects([
      AgentEffects,
      AgencyEffects,
      CampaignEffects,
      CampaignHopperEffects,
      CampaignLiveStatsEffects,
      CampaignOnlineStatsEffects,
      ConsumerEffects,
      DialerAgentEffects,
      DispoStatusEffects,
      EmployeeEffects,
      LeadEffects,
      LeadCallEffects,
      LeadListEffects,
      NumberEffects,
      PermissionEffects,
      PermissionGrantedEffects,
      QuestionnaireEffects,
      QueueEffects,
      QueueLiveStatsEffects,
      ScriptEffects,
      StaticEffects,
      SkillEffects,
    ]),
    !environment.production ? provideStoreDevtools() : [],
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideSentryErrorHandler({
      showDialog: false,
    }),
    provideSentryTraceService(),
    provideSentryInitializer(),
    provideToastr(),
    AuthGuard,
  ],
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ScriptActions } from './script.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerScript, Questionnaire } from 'src/app/shared/models';
import { questionnaireToJSON } from 'src/app/shared/models/questionnaire';
import { convertDotNotationToObject } from 'src/app/shared/misc/convertObjectToDotNotation';

@Injectable()
export class ScriptEffects {
  loadScripts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptActions.loadScripts),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'scripts'),
            (snapshot) => {
              const scripts = snapshot.docs.map((doc) => {
                const data = doc.data()

                if (data?.['questionnaire']) {
                  data['questionnaire'] = convertDotNotationToObject(data['questionnaire'])
                }

                return DialerScript.fromJSON({ ...data, id: doc.id, agencyId })
              });
              subscriber.next(
                ScriptActions.loadScriptsSuccess({ scripts }),
              );
            },
            (error) => {
              subscriber.next(ScriptActions.loadScriptsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Script API] Load Scripts Error', error }),
          ),
        );
      }),
    );
  });

  loadScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptActions.loadScript),
      switchMap(({ scriptId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'scripts', scriptId),
            (snapshot) => {
              const data = snapshot.data();

              if (data?.['questionnaire']) {
                data['questionnaire'] = convertDotNotationToObject(data['questionnaire'])
              }

              const script = DialerScript.fromJSON({
                ...data,
                id: snapshot.id,
                agencyId
              });

              subscriber.next(
                ScriptActions.loadScriptSuccess({ script }),
              );
            },
            (error) => {
              subscriber.next(ScriptActions.loadScriptFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptActions.addScript),
      mergeMap(async ({ script, agencyId }) => {
        console.log('addScriptAction', script);

        let questionnaire: any = {}
        if (script.questionnaire) {
          // questionnaire = Questionnaire.toJSON(script.questionnaire)
          questionnaire = questionnaireToJSON(script.questionnaire)
        }

        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'scripts'),
            { ...(DialerScript.toJSON(script) as any), questionnaire },
          );
          return ScriptActions.addScriptSuccess({
            script: DialerScript.fromJSON({ ...script, id: docRef.id, agencyId }),
          }); // return new script with id
        } catch (error) {
          return ScriptActions.addScriptFailure({ error });
        }
      }),
    );
  });

  removeScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptActions.removeScript),
      mergeMap(async ({ scriptId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'scripts', scriptId),
          );
          return ScriptActions.removeScriptSuccess({ scriptId }); // return removed script's id
        } catch (error) {
          return ScriptActions.removeScriptFailure({ error });
        }
      }),
    );
  });

  updateScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScriptActions.updateScript),
      mergeMap(async ({ scriptId, script, agencyId, merge }) => {
        console.log('updateScriptAction', scriptId, script);

        if (merge === undefined || merge === null) {
          merge = true
        }

        let questionnaire: any = {}
        if (script.questionnaire) {
          questionnaire = questionnaireToJSON(script.questionnaire)
        }

        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'scripts', scriptId),
            { ...(DialerScript.toJSON(script) as any), questionnaire },
            {
              merge: merge,
            },
          );
          return ScriptActions.updateScriptSuccess({
            scriptId,
            agencyId,
            script: { ...script, agencyId },
          }); // return updated script's id and changes
        } catch (error) {
          return ScriptActions.updateScriptFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CampaignActions } from './campaign.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerCampaign } from 'src/app/shared/models';

@Injectable()
export class CampaignEffects {
  loadCampaigns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignActions.loadCampaigns),
      mergeMap(({ agencyId }) => {
        console.log('loadCampaignsAction', agencyId)
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'campaigns'),
            (snapshot) => {
              const campaigns = snapshot.docs.map((doc) =>
                DialerCampaign.fromJSON({ ...doc.data(), id: doc.id, agencyId }),
              );
              subscriber.next(
                CampaignActions.loadCampaignsSuccess({ campaigns }),
              );
            },
            (error) => {
              subscriber.next(CampaignActions.loadCampaignsFailure({ error }));
            },
          );
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Campaign API] Load Campaigns Error', error }),
          ),
        );
      }),
    );
  });

  loadCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignActions.loadCampaign),
      switchMap(({ campaignId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'campaigns', campaignId),
            (snapshot) => {
              const campaign = DialerCampaign.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId
              });
              subscriber.next(
                CampaignActions.loadCampaignSuccess({ campaign }),
              );
            },
            (error) => {
              subscriber.next(CampaignActions.loadCampaignFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignActions.addCampaign),
      mergeMap(async ({ campaign, agencyId }) => {
        console.log('addCampaignAction', campaign);
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'campaigns'),
            campaign,
          );
          return CampaignActions.addCampaignSuccess({
            campaign: DialerCampaign.fromJSON({ ...campaign, id: docRef.id, agencyId }),
          }); // return new campaign with id
        } catch (error) {
          return CampaignActions.addCampaignFailure({ error });
        }
      }),
    );
  });

  removeCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignActions.removeCampaign),
      mergeMap(async ({ campaignId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'campaigns', campaignId),
          );
          return CampaignActions.removeCampaignSuccess({ campaignId }); // return removed campaign's id
        } catch (error) {
          return CampaignActions.removeCampaignFailure({ error });
        }
      }),
    );
  });

  updateCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignActions.updateCampaign),
      mergeMap(async ({ campaignId, campaign, agencyId }) => {
        console.log('updateCampaignAction', campaignId, campaign);
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'campaigns', campaignId),
            campaign,
            {
              merge: true,
            },
          );
          return CampaignActions.updateCampaignSuccess({
            campaignId,
            agencyId,
            campaign: { ...campaign, agencyId },
          }); // return updated campaign's id and changes
        } catch (error) {
          return CampaignActions.updateCampaignFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NumberActions } from './number.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerNumber } from 'src/app/shared/models';

@Injectable()
export class NumberEffects {
  loadNumbers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NumberActions.loadNumbers),
      mergeMap(({ agencyId }) => {
        console.log('loadNumbersAction', agencyId)
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'numbers'),
            (snapshot) => {
              const numbers = snapshot.docs.map((doc) =>
                DialerNumber.fromJSON({ ...doc.data(), id: doc.id, agencyId }),
              );
              subscriber.next(
                NumberActions.loadNumbersSuccess({ numbers }),
              );
            },
            (error) => {
              subscriber.next(NumberActions.loadNumbersFailure({ error }));
            },
          );
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Number API] Load Numbers Error', error }),
          ),
        );
      }),
    );
  });

  loadNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NumberActions.loadNumber),
      switchMap(({ numberId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'numbers', numberId),
            (snapshot) => {
              const number = DialerNumber.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId
              });
              subscriber.next(
                NumberActions.loadNumberSuccess({ number }),
              );
            },
            (error) => {
              subscriber.next(NumberActions.loadNumberFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NumberActions.addNumber),
      mergeMap(async ({ number, agencyId }) => {
        console.log('addNumberAction', number);
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'numbers'),
            number,
          );
          return NumberActions.addNumberSuccess({
            number: DialerNumber.fromJSON({ ...number, id: docRef.id, agencyId }),
          }); // return new number with id
        } catch (error) {
          return NumberActions.addNumberFailure({ error });
        }
      }),
    );
  });

  removeNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NumberActions.removeNumber),
      mergeMap(async ({ numberId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'numbers', numberId),
          );
          return NumberActions.removeNumberSuccess({ numberId }); // return removed number's id
        } catch (error) {
          return NumberActions.removeNumberFailure({ error });
        }
      }),
    );
  });

  updateNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NumberActions.updateNumber),
      mergeMap(async ({ numberId, number, agencyId }) => {
        console.log('updateNumberAction', numberId, number);
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'numbers', numberId),
            number,
            {
              merge: true,
            },
          );
          return NumberActions.updateNumberSuccess({
            numberId,
            agencyId,
            number: { ...number, agencyId },
          }); // return updated number's id and changes
        } catch (error) {
          return NumberActions.updateNumberFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

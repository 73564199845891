import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CampaignOnlineStatsActions } from './campaign-online-stats.actions';
import {
  Firestore,
  collection,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerCampaign_OnlineStats } from 'src/app/shared/models';

@Injectable()
export class CampaignOnlineStatsEffects {
  loadCampaignOnlineStats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignOnlineStatsActions.loadCampaignOnlineStats),
      mergeMap(({ agencyId, campaignId }) => {
        return new Observable<Action>((subscriber) => {
          onSnapshot(
            doc(
              this.firestore,
              'agencies',
              agencyId,
              'campaigns',
              campaignId,
              'readyQueueControl',
              'agents',
            ),
            (snapshot) => {
              console.log('STAT EXISTS', agencyId, campaignId, snapshot.exists());
              const data = snapshot.data();
              console.log('STAT load ', agencyId, campaignId, data);

              const onlineCount = Object.keys(data?.['online'] ?? {})?.length;
              const campaignOnlineStat = DialerCampaign_OnlineStats.fromJSON({
                campaignId,
                onlineCount,
                ...data,
              });
              subscriber.next(
                CampaignOnlineStatsActions.loadCampaignOnlineStatsSuccess({
                  campaignOnlineStat,
                }),
              );
            },
            (error) => {
              subscriber.next(
                CampaignOnlineStatsActions.loadCampaignOnlineStatsFailure({
                  error,
                }),
              );
            },
          );

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({
              type: '[CampaignOnlineStats API] Load CampaignOnlineStats Error',
              error,
            }),
          ),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { QuestionnaireActions } from './questionnaire.actions';
import {
  Firestore,
  collection,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';

@Injectable()
export class QuestionnaireEffects {
  loadQuestionnaires$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActions.loadQuestionnaires),
      switchMap(({ agencyId, leadId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'leads', leadId, 'questionnaires'),
            (snapshot) => {
              const questionnaires = snapshot.docs.map((doc) =>
                ({ ...doc.data(), id: doc.id, agency: { id: agencyId }, lead: { id: leadId } }),
              );
              subscriber.next(QuestionnaireActions.loadQuestionnairesSuccess({ questionnaires }));
            },
            (error) => {
              subscriber.next(QuestionnaireActions.loadQuestionnairesFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Questionnaire API] Load Questionnaires Error', error }),
          ),
        );
      }),
    );
  });


  loadQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActions.loadQuestionnaire),
      switchMap(({ questionnaireId, leadId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'questionnaires', questionnaireId),
            (snapshot) => {
              const questionnaire: any = ({
                ...snapshot.data(),
                id: snapshot.id,
              });
              questionnaire.agency = { id: agencyId };
              questionnaire.lead = { id: leadId };
              subscriber.next(QuestionnaireActions.loadQuestionnaireSuccess({ questionnaire }));
            },
            (error) => {
              subscriber.next(QuestionnaireActions.loadQuestionnaireFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  // addQuestionnaire$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(QuestionnaireActions.addQuestionnaire),
  //     mergeMap(async ({ questionnaire, agencyId, leadId }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'agencies', agencyId, 'leads', leadId, 'questionnaires'),
  //           questionnaire,
  //         );
  //         return QuestionnaireActions.addQuestionnaireSuccess({
  //           questionnaire: Consumer.fromJSON({ ...questionnaire, id: docRef.id }),
  //         }); // return new questionnaire with id
  //       } catch (error) {
  //         return QuestionnaireActions.addQuestionnaireFailure({ error });
  //       }
  //     }),
  //   );
  // });

  // removeQuestionnaire$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(QuestionnaireActions.removeQuestionnaire),
  //     mergeMap(async ({ questionnaireId, agencyId, leadId }) => {
  //       try {
  //         await deleteDoc(
  //           doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'questionnaires', questionnaireId),
  //         );
  //         return QuestionnaireActions.removeQuestionnaireSuccess({
  //           questionnaireId,
  //           agencyId,
  //         }); // return removed questionnaire's id
  //       } catch (error) {
  //         return QuestionnaireActions.removeQuestionnaireFailure({ error });
  //       }
  //     }),
  //   );
  // });

  updateQuestionnaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuestionnaireActions.updateQuestionnaire),
      mergeMap(async ({ questionnaireId, agencyId, questionnaire, leadId }) => {
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'questionnaires', questionnaireId),
            questionnaire,
            {
              merge: true,
            },
          );
          return QuestionnaireActions.updateQuestionnaireSuccess({
            questionnaireId,
            agencyId,
            questionnaire,
            leadId
          }); // return updated questionnaire's id and changes
        } catch (error) {
          return QuestionnaireActions.updateQuestionnaireFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

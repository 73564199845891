import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { StaticActions } from './static.actions';
import { Firestore, doc, onSnapshot } from '@angular/fire/firestore';
import { Action } from '@ngrx/store';

@Injectable()
export class StaticEffects {

  loadTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.loadTags),
      switchMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'static', 'tags'),
            (snapshot) => {
              const data: any = snapshot?.data()

              const tags = data?.tags ? Object.keys(data?.tags) : null

              subscriber.next(
                StaticActions.loadTagsSuccess({ tags }),
              );
            },
            (error) => {
              subscriber.next(StaticActions.loadTagsFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });
  constructor(private actions$: Actions, private firestore: Firestore) { }
}

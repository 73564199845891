import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { QueueActions } from './queue.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerQueue } from 'src/app/shared/models';

@Injectable()
export class QueueEffects {
  loadQueues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.loadQueues),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'queues'),
            (snapshot) => {
              const queues = snapshot.docs.map((doc) =>
                DialerQueue.fromJSON({ ...doc.data(), id: doc.id, agencyId, agency: { id: agencyId }, }),
              );
              subscriber.next(
                QueueActions.loadQueuesSuccess({ queues }),
              );
            },
            (error) => {
              subscriber.next(QueueActions.loadQueuesFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Queue API] Load Queues Error', error }),
          ),
        );
      }),
    );
  });

  loadQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.loadQueue),
      switchMap(({ queueId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'queues', queueId),
            (snapshot) => {
              const queue = DialerQueue.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId,
                agency: { id: agencyId },
              });
              subscriber.next(
                QueueActions.loadQueueSuccess({ queue }),
              );
            },
            (error) => {
              subscriber.next(QueueActions.loadQueueFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.addQueue),
      mergeMap(async ({ queue, agencyId }) => {
        console.log('addQueueAction', queue);
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies', agencyId, 'queues'),
            DialerQueue.toJSON(queue) as DocumentData,
          );
          return QueueActions.addQueueSuccess({
            queue: DialerQueue.fromJSON({ ...queue, id: docRef.id, agencyId, agency: { id: agencyId }, }),
          }); // return new queue with id
        } catch (error) {
          return QueueActions.addQueueFailure({ error });
        }
      }),
    );
  });

  removeQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.removeQueue),
      mergeMap(async ({ queueId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'queues', queueId),
          );
          return QueueActions.removeQueueSuccess({ queueId }); // return removed queue's id
        } catch (error) {
          return QueueActions.removeQueueFailure({ error });
        }
      }),
    );
  });

  updateQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.updateQueue),
      mergeMap(async ({ queueId, queue, agencyId }) => {
        console.log('updateQueueAction', queueId, queue);
        try {
          await setDoc(
            doc(this.firestore, 'agencies', agencyId, 'queues', queueId),
            DialerQueue.toJSON((queue as DialerQueue)) as DocumentData,
            {
              merge: true,
            },
          );
          return QueueActions.updateQueueSuccess({
            queueId,
            agencyId,
            queue: { ...queue, agencyId, agency: { id: agencyId }, },
          }); // return updated queue's id and changes
        } catch (error) {
          console.error('updateQueueError', error);
          return QueueActions.updateQueueFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}

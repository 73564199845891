import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';
import { Observable, Subscriber } from 'rxjs';
import { CampaignHopperActions } from './campaign-hopper.actions';
import { Action } from '@ngrx/store';
import { DialerCampaignHopper } from 'src/app/shared/models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class CampaignHopperEffects {
  private intervalRunning = false
  private taskIntervals: { [key: string]: { [key: string]: any } } = {}

  getCloudTasks = (campaigns: { agencyId: string, queueId: string }[]) => new Promise(async (resolve, reject) => {
    const currentToken = await this.authService.getIdToken();

    const results = []
    for (const campaign of campaigns) {
      const { agencyId, queueId } = campaign
      const res: any = await new Promise((r) => {
        this.http
          .post<any>(`${environment.callControlEndpoint}/getQueueInfo`, {
            agencyId: agencyId, campaignId: queueId
          }, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .subscribe({
            next: (res) => {
              // this.cloudTask = res?.queue
              r(res);
            }, error: (error) => {
              console.error('getCloudTasks error', error)
              r(null)
            }
          });

      })
      results.push({ agencyId, queueId, queue: res?.queue })
    }

    resolve(results)
  });

  loadCampaignHopper$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampaignHopperActions.loadCampaignHopper),
      mergeMap(({ queueId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          if (!this.taskIntervals[agencyId]) {
            this.taskIntervals[agencyId] = {}
          }

          if (!this.queueIds[agencyId]) {
            this.queueIds[agencyId] = {}
          }

          this.queueIds[agencyId][queueId] = subscriber

          if (!this.intervalRunning) {
            this.runGetCloudTask()


            if(!this.queueIdIntervals[agencyId]){
              this.queueIdIntervals[agencyId] = {}
            }

            if (this.queueIdIntervals[agencyId][queueId]) {
              clearInterval(this.queueIdIntervals[agencyId][queueId])
            }

            this.queueIdIntervals[agencyId][queueId] = setInterval(() => {
              this.runGetCloudTask()
            }, 10000)
            this.intervalRunning = true
          }

          return () => {
            this.queueIds[agencyId][queueId]?.unsubscribe()
            delete this.queueIds[agencyId][queueId]
          };

        });
      }),
    );
  });

  queueIds: { [key: string]: { [key: string]: Subscriber<Action> } } = {}
  queueIdIntervals: { [key: string]: { [key: string]: NodeJS.Timeout } } = {}

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private http: HttpClient
  ) {
    // this.runGetCloudTask()
    // setInterval(() => {
    //   this.runGetCloudTask()
    // }, 10000)
  }

  runGetCloudTask = () => {
    const campaigns = []

    for (const agencyId in this.queueIds) {
      for (const queueId in this.queueIds[agencyId]) {
        campaigns.push({ agencyId, queueId })
      }
    }


    const results = this.getCloudTasks(campaigns)

    results.then((res: any) => {
      for (const r of res) {
        const { queueId, agencyId } = r

        if (this.queueIds[agencyId][queueId]) {
          this.queueIds[agencyId][queueId].next(CampaignHopperActions.loadCampaignHopperSuccess({
            queueId: queueId,
            agencyId: agencyId,
            campaignHopper: DialerCampaignHopper.fromJSON({ ...r.queue, queueId, agencyId })
          }))
        }
      }
    })

    // if (res.queue) {
    //   CampaignHopperActions.loadCampaignHopperSuccess({ queueId, agencyId, campaignHopper: DialerCampaignHopper.fromJSON({ ...res.queue, queueId, agencyId }) });
    // }
  }
}
